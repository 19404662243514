import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const BlogPost = ({data}) => (
  <Layout>
    <Seo title={data.wpPost.title} />
    <div>
      <h1 className="text-scplightgrey" >{data.wpPost.title}
      <span className="hidden text-sm font-normal -mt-1 mb-4 text-scporange md:block">
        Posted by {data.wpPost.author.node.name} on {data.wpPost.date}
      </span>
      </h1>
    </div>
    <div className="blog text-default mb-16" dangerouslySetInnerHTML={{ __html: data.wpPost.content }} />
  </Layout>
)

export const query = graphql`
  query PostQuery($id : String) {
    wpPost(id: {eq: $id}) {
      databaseId
      title
      content
      date(formatString: "YYYY-MM-DD")
      author {
        node {
          name
        }
      }
    }
  }
`

export default BlogPost
